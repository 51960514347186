.AppPlatformContainer {
  padding: 20px 100px;


  /* background: #f9f5ff; */
}

.AppPlatformSectionContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 25px 0px;
}

.AppPlatformSectionHeader {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  color: #F04438;
}

.AppPlatformSectionText {
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  color: #1d2939;
}

.AppPlatformImgContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 40px;
}

.AppPlatformImgSection {
  width: 324px;
  height: 324px;
  border-radius: 8px;
}

.AppPlatformContentSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  width: 806px;
  /* height: 260px; */
}

.AppPlatformSection {
  width: 500px;
  height: 500px;
}

.AppPlatformContentSection ul {
  list-style-type: none;
  margin: 25px 0px;
}

.AppPlatformContentSectionPoints {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #475467;
}

.AppPlatformHeading {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #F04438;
  padding-left: 2rem;
}

.AppPlatformContent {
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  color: #1d2939;
  padding-left: 2rem;
}

.AppPlatformBtns {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 24px;
  padding-left: 2rem;
}

@media (max-width: 550px) {
  .AppPlatformImgContainer {
    flex-direction: column !important;
  }

  .AppPlatformContentSection {
    width: unset;
  }

  .AppPlatformImg {
    width: 8rem;
  }

  .AppPlatformSection {
    width: 390px;
    height: 400px;
  }

  .AppPlatformContainer {
    padding: 20px;
  }
}