.FooterContainer {
  background: #F04438;
  padding-top: 20px;
}

.FooterManagement {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
}

.FooterHeading {
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  color: #fcfaff;
}

.FooterContent {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #FFF;
  display: flex;
}

.FooterContent a,
.FooterBottomPolicyTerms a {
  text-decoration: none;
  color: #FFF;

}

.FooterBottomLinksName {
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  color: #fcfaff;
  margin-right: 15px;
}

.FooterBottomLinks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0rem;
}

.FooterBottomLinks1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0rem;
}

.FooterBottomLinks2Name {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  /* Foundation/Primary/200 */
  width: 868px;
  color: #FFF;
}

.FooterBottomPolicyTerms {
  display: flex;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #FFF;
}


.FooterLinksColor {
  color: #FFF;
}

@media (max-width: 550px) {

  .FooterBottomLinks {
    justify-content: center !important;
  }

  .FooterBottomLinks1 {
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 2rem;
  }

  .FooterBottomLinksSection {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .FooterManagement {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}