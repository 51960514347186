.AutomatedAppProcessContainer {
  padding: 30px 100px;
  gap: 100px;
  background: #ffffff;
}

.AutomatedAppProcessLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
}

.AutomatedAppProcessLeftHeading {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #F04438;
}

.AutomatedAppProcessLeftText {
  font-weight: 700;
  font-size: 48px;
  line-height: 59px;
  color: #1d2939;
}

.AutomatedAppProcessRight {
  isolation: isolate;
  width: 100% !important;
  height: 100% !important;
  background: #f9f5ff;
  border-radius: 20px;
}


@media (max-width:550px) {
  .AutomatedAppProcessRight {
    width: 330px;
    height: 250px;
    margin: 2rem 0rem;
  }

  .AutomatedAppProcessContainer {
    padding: 20px;
  }
}