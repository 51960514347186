.Section3Container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 100px;
  gap: 40px;
  background: #ffffff;
  flex: none;
  order: 2;
  flex-grow: 0;
}

.Section3TopContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 2rem;
}

.Section3Content li {
  list-style-type: none;
}

.Section3Row {
  display: flex;
  align-items: center;
}

.Section3Heading {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #1d2939;
}

.Section3Content {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #475467;
  margin: 1rem -2rem;
}

/* .Section3Container */
.Section3LeftBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 600px;
  /* height: 100px; */
  background: #ffffff;
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
}

.Section3Header {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #F04438;
}

.Section3Text {
  font-weight: 700;
  font-size: 28px;
  color: #1d2939;
}

@media (max-width: 550px) {
  .Section3Container {
    padding: 20px;
  }

  .Section3LeftBox {
    width: unset;
  }

  .Section3__RightImage {
    width: 340px;
  }
}