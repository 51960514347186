.BenefitSectionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
  gap: 40px;
  /* width: 100vw; */
  /* height: 771px; */
  background: #f9fafb;
  flex: none;
  order: 2;
}

.BenefitSectionContent {
  font-family: "Gilroy";
  font-style: normal;
  align-items: center;
  text-align: center;
  padding: 0px;
  gap: 8px;
  /* width: 1240px; */
  /* height: 65px; */
}

.BenefitSectionHeader {
  font-weight: 700;
  font-size: 28px;
  color: #1d2939;
}

.BenefitSectionText {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #F04438;
}

.BenefitSectionBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  gap: 20px;
  /* width: 386.67px; */
  background: #ffffff;
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 15px 0px;
}