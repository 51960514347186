.header {
  background: #FFFFFF;
  color: #fff;
  padding: 1rem;
  position: sticky;
  top: 0;
  z-index: 1;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-weight: bold;
  font-size: 1.5rem;
  margin-left: 5rem;
}

.menu-toggle {
  display: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.menu-toggle .sr-only {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.icon-bar {
  display: block;
  width: 18px;
  height: 2px;
  border-radius: 1px;
  background-color: #fff;
  margin: 4px 0;
}

.menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-right: 2rem;
}

.menu li {
  margin: 0 1rem;
}

.menu li a {
  color: black;
  text-decoration: none;
  font-size: 1.2rem;
}

.menu li a:hover {
  color: #F04438;
}

.headerButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 40px;
  gap: 12px;
  /* width: 188px; */
  height: 49px;
  background: #F04438;
  border: 1px solid #F04438;
  color: #FCFAFF;
  border-radius: 4px;
}

.headerButton2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 40px;
  gap: 10px;
  /* width: 210px; */
  height: 49px;
  border: 1px solid #F04438;
  color: #F04438;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }

  .menu {
    position: absolute;
    top: 5%;
    left: 0;
    width: 100%;
    flex-direction: column;
    background-color: #FFFFFF;
    transition: transform 0.3s ease-in-out;
    transform: translateY(-100%);
  }

  .logo {
    margin-left: 0rem;
  }

  .menu.open {
    transform: translateY(0);
  }

  .menu.close {
    display: none;
  }

  .menu li {
    margin: 1rem 0;
    width: 100%;
    padding-left: 30px;
  }
}