.custom-tabs .nav-link.active {
    color: #FFF;
    border-bottom: 2px solid red;
    /* font-family: "Inter-Medium"; */
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    background-color: #DD2737;
}

.custom-tabs .nav-tabs .nav-link {
    margin-bottom: calc(-1* var(--bs-nav-tabs-border-width));
    border: none;
    border-top-left-radius: var(--bs-nav-tabs-border-radius);
    border-top-right-radius: var(--bs-nav-tabs-border-radius);
}

.custom-tabs .nav-link {
    display: block;
    padding: 10px 10px;
    font-size: 20px;
    color: #DD2737;
    text-decoration: none;
    /* background: 0 0; */
    font-weight: 600;
    /* border: 0; */
    /* transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out; */
    padding-left: 0;
    margin-right: 20px;
    background-color: #FFF;
    border: 1px solid #DD2737;
}

.top_header {
    color: var(--Gray-900, #DD2737);
    font-size: 1.875rem;
    font-family: "Inter-Medium";
    font-weight: 600;
    line-height: 2.375rem;
    /* 126.667% */
}

.custom-tabs {
    border-bottom: 2px solid #ded7d7;
}

.inside_tab {
    background-color: #FFF;
}

.event_section {
    display: flex;
    justify-content: flex-start;
    gap: 25px;
}

@media screen and (min-width:0px)and (max-width:900px) {
    .event_section {
        display: block;
        justify-content: flex-start;
        gap: 20px;
    }
}