.ContactUsForm__Main {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 60px 100px;
  gap: 40px;
  background: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.ContactUsForm__Main1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 0px;
  gap: 40px;
  background: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.ContactUsForm__Container {
  align-items: center;
  padding: 0px;
  gap: 40px;
}

.ContactUsForm__heading1 {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #F04438;
}

.ContactUsForm__heading2 {
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  color: #1d2939;
  margin-bottom: 3rem;
}

.ContactUsForm__label {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #667085;
  margin: 10px 0px;
}

.ContactUsForm__input {
  border: 1px solid #e4e7ec;
  border-top: none;
  border-left: none;
  border-right: none;
  width: 80%;
  outline: none;
}

.ContactUsForm__input {
  width: 100% !important;
}

.ContactUsForm__button {
  padding: 15px 40px;
  gap: 12px;
  background: #F04438;
  border: 1px solid #F04438;
  border-radius: 4px;
  color: white;
  margin-top: 2rem;
}

.ContactUsForm__Right {
  /* display: flex; */
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  gap: 10px;
  background: #fcfaff;
  border: 1px solid #d6bbfb;
  border-radius: 8px;
}

.ContactUsForm__rightContent {
  display: flex;
  flex-direction: row;
}

.ContactUsForm__LocationImg {
  width: 100%;
  height: 250px;
}

.ContactUsForm__headingColor {
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  color: #F04438 !important;
  margin-bottom: 10px !important;
}

.ContactUsForm__row {
  margin: 10px 0px;
}


@media (max-width:550px) {
  .ContactUsForm__Main {
    padding: 20px;
  }

  .ContactUsForm__Main1 {
    padding: 20px;
  }

  .ContactUsForm__button {
    margin-bottom: 3rem;
  }

  .ContactUsForm__LocationImg {
    height: unset;
  }
}