.wrapper {
  max-width: 1090px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.cardHeading {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #1d2939;
  margin-top: 2.5rem;
}

.priceContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
  margin: 15px 0px;
}

.priceNumber {
  font-weight: 700;
  font-size: 48px;
  line-height: 59px;
  color: #F04438;
}

.priceText {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #F04438;
}

.wrapper .table {
  background: #fff;
  width: calc(33% - 20px);
  padding: 30px 30px;
  position: relative;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.table .priceSection {
  display: flex;
  justify-content: center;
}

.table .priceArea {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  padding: 2px;
}

.priceArea .innerArea {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 3px solid #fff;
  line-height: 117px;
  text-align: center;
  color: #fff;
  position: relative;
}

.priceArea .innerArea .text {
  font-size: 25px;
  font-weight: 400;
  position: absolute;
  top: -10px;
  left: 17px;
}

.priceArea .innerArea .price {
  font-size: 45px;
  font-weight: 500;
  margin-left: 16px;
}

.table .packageName {
  width: 100%;
  height: 2px;
  margin: 35px 0;
  position: relative;
}

.table .packageName::before {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 25px;
  font-weight: 500;
  background: #fff;
  padding: 0 15px;
  transform: translate(-50%, -50%);
}

.table .features {
  padding-left: 0px;
}

.table .features li {
  margin-bottom: 15px;
  list-style: none;
  display: flex;
  /* justify-content: space-between; */
}

.features li .listName {
  font-size: 17px;
  font-weight: 400;
}

.features li .icon {
  font-size: 15px;
}

.features li .icon.check {
  color: #2db94d;
}

.features li .icon.cross {
  color: #cd3241;
}

.table .btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

.table .btn button {
  /* padding: 15px 40px; */
  gap: 10px;
  width: 272px;
  height: 49px;
  border: 1px solid #F04438;
  border-radius: 4px;
}

.table .btn button:hover {
  border-radius: 5px;
}

.basic .features li::selection {
  background: #ffd861;
}

.basic ::selection,
.basic .priceArea,
.basic .innerArea {
  background: #ffd861;
}

.basic .btn button {
  border: 2px solid #6941C6;
  background: #fff;
  color: #6941C6;
}

.basic .btn button:hover {
  background: #6941C6;
  color: #fff;
}

.premium ::selection,
.premium .priceArea,
.premium .innerArea,
.premium .btn button {
  background: white;
  border: 1px solid #6941C6;
  color: #6941c6;
}

.premium .btn button:hover {
  background: #6941C6;
  color: #fff;
}

.premium .btn button:hover {
  background: #833af8;
}

.ultimate ::selection,
.ultimate .priceArea,
.ultimate .innerArea {
  background: #43ef8b;
}

.ultimate .btn button {
  border: 2px solid #6941C6;
  color: #6941C6;
  background: #fff;
}

.ultimate .btn button:hover {
  background: #6941C6;
  color: #fff;
}

.basic .packageName {
  background: #ffecb3;
}

.premium .packageName {
  background: #d0b3ff;
}

.ultimate .packageName {
  background: #baf8d4;
}

.basic .packageName::before {
  content: "Basic";
}

.premium .packageName::before {
  content: "Premium";
  font-size: 24px;
}

.ultimate .packageName::before {
  content: "Ultimate";
  font-size: 24px;
}

@media (max-width: 1020px) {
  .wrapper .table {
    width: calc(50% - 20px);
    margin-bottom: 40px;
  }
}

@media (max-width: 698px) {
  .wrapper .table {
    width: 100%;
  }
}

::selection {
  color: #fff;
}

.table .ribbon {
  width: 150px;
  height: 150px;
  position: absolute;
  top: -10px;
  left: -10px;
  overflow: hidden;
}

.table .ribbon::before,
.table .ribbon::after {
  position: absolute;
  content: "";
  z-index: -1;
  display: block;
  border: 7px solid #4606ac;
  border-top-color: transparent;
  border-left-color: transparent;
}

.table .ribbon::before {
  top: 0px;
  right: 15px;
}

.table .ribbon::after {
  bottom: 15px;
  left: 0px;
}

.table .ribbon span {
  position: absolute;
  top: 30px;
  right: 0;
  transform: rotate(-45deg);
  width: 200px;
  background: #a26bfa;
  padding: 10px 0;
  color: #fff;
  text-align: center;
  font-size: 17px;
  text-transform: uppercase;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12);
}