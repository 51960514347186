.TabsSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  background: #f9fafb;
}

.TabsSection a {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #F04438;
  text-decoration: none;
}

a.activeTab {
  font-weight: bold;
  color: blue;
  padding: 0.8rem;
  border-bottom: 3px solid #F04438;
  width: 150px;
  text-align: center;
}


@media (max-width:550px) {
  .TabsSection {
    width: 90%;
    font-size: 15px;
  }
}