.ChatHeroSectionContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 30px 100px 30px 100px;
  gap: 59px;
  /* width: 1440px; */
  height: 450px;
  background: #f9f5ff;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.ChatHeroImageTopText {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #F04438;
}

.ChatHeroSectionLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  /* gap: 40px; */
  /* width: 509px; */
  /* height: 348px; */
  order: 0;
}

.ChatHeroSectionLeftContent {
  gap: 16px;

  flex: none;
  align-self: stretch;
  font-family: "Gilroy";
  font-style: normal;
}

.ChatHeroSectionLeftHeading {
  font-weight: 700;
  font-size: 48px;
  color: #F04438;
  font-family: "Gilroy";
}

.ChatHeroSectionLeftText {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #475467;
}

.ChatHeroSectionleftButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
  margin-top: 2rem;
}

.ChatHeroSectionleftButton1 {
  padding: 12px 40px;
  gap: 12px;
  background: #F04438;
  border: 1px solid #F04438;
  border-radius: 4px;
  color: white;
}

.ChatHeroSectionleftButton2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;
}

.ChatHeroSectionRight,
.ChatHeroSectionRightImg {
  width: 572px;
  height: 450px;
}

@media (max-width: 550px) {
  .ChatHeroSectionContainer {
    flex-direction: column;
    height: unset;
    padding: 20px;
  }

  .ChatHeroSectionLeft {
    width: 100%;
    gap: 50px;
  }

  .ChatHeroSectionLeftContent {
    height: unset;
    width: unset;
  }

  .ChatHeroSectionRight,
  .ChatHeroSectionRightImg {
    width: 387px;
    height: 337px;
    /* margin-top: 5rem !important; */
  }
}