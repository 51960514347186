.Error404__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 100px;
  gap: 10px;
  background: #f9f5ff;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.Error404_text1 {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  color: #F04438;
}

.Error404_text2 {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #1d2939;
}

.Error404_button {
  padding: 15px 40px;
  gap: 12px;
  background: #F04438;
  border: 1px solid #F04438;
  border-radius: 4px;
}

.Error404_image {
  height: 387px;
  width: 387px;
}