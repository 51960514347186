.ApplicationWorksContainer {
  padding: 20px 100px;
  gap: 40px;

  /* background: #F9FAFB; */
  ;
}

.ApplicationWorksSectionContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 25px 0px;
}

.ApplicationWorksSectionHeader {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  color: #F04438;
}

.ApplicationWorksSectionText {
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  color: #1d2939;
}

.ApplicationWorksImgContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px;
  gap: 40px;
  /* background-color: #F9F5FF; */
}

.ApplicationWorksImgSection {
  width: 324px;
  height: 324px;
  border-radius: 8px;
}

.ApplicationWorksContentSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  width: 876px;
  /* height: 260px; */
}

.ApplicationWorksContentSection ul {
  list-style-type: none;
  margin: 25px 0px;
}

.ApplicationWorksContentSectionPoints {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #475467;
}


@media (max-width:550px) {
  .ApplicationWorksImgContainer {
    flex-direction: column !important;
  }

  .ApplicationWorksContentSection {
    width: unset;
  }

  .ApplicationWorksSection {
    width: 300px;
  }

  .ApplicationWorksContainer {
    padding: 20px;
  }
}