.TabsSectionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 100px 0px;
  gap: 40px;
  background: #f9fafb;
  flex: none;
  order: 3;
  flex-grow: 0;
}


@media (max-width:550px){
    .TabsSectionContainer{
        padding: 20px;
    }

   
}