.ProductSectionContainer {
  display: flex;
  flex-direction: column;

  gap: 40px;
  background: #ffffff;
}

.ProductSectionBox {
  padding: 0px;
  gap: 8px;
  /* width: 200px;
  height: 243px; */
}

.ProductSectionImgDetails {
  text-align: center;
  padding: 8px 16px;
  gap: 10px;
  width: 200px;
  height: 35px;
  background: #f9f5ff;
  border: 1px solid #e9d7fe;
  border-radius: 4px;
}

.ProductSectionColumn {
  display: flex;
  justify-content: center;
}

.ProductSectionContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 25px 0px;
}

.ProductSectionHeader {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  color: #F04438;
}

.ProductSectionText {
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  color: #1d2939;
}


@media (max-width:550px) {
  .ProductSectionColumn {
    margin: 2rem 0rem;
  }
}