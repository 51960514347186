.ContactUsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 60px 30px;
  gap: 24px;

  /* width: 1440px; */
  height: 169px;
  background: #F04438;
}

.ContactUsContent {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #fcfaff;
}

.ContactUsButton {
  gap: 12px;
  width: 163px;
  height: 49px;
  background: #fcfaff;
  border: 1px solid #fcfaff;
  border-radius: 4px;
  color: #F04438;
}