.FaqContainer {
  padding: 60px 100px;
  gap: 40px;

  background: #F9FAFB;
}

.FaqSectionContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* text-align: center; */
  margin: 25px 0px;
}

.FaqSectionHeader {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* text-align: center; */
  text-transform: uppercase;
  color: #F04438;
}

.FaqSectionText {
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  color: #1d2939;
}

.FaqImgContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 40px;
}

.FaqImgSection {
  width: 324px;
  height: 324px;
  border-radius: 8px;
}

.FaqSection {
  width: 500px;
  height: 500px;
}

.FaqContentSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  width: 806px;
  /* height: 260px; */
}

.FaqContentSection ul {
  list-style-type: none;
  margin: 25px 0px;
}

.FaqContentSectionPoints {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #475467;
}


@media (max-width:550px) {
  .FaqImgContainer {
    flex-direction: column !important;
  }

  .FaqContentSection {
    width: unset;
  }

  .FaqSection {
    width: 390px;
    height: 400px;
  }

  .FaqContainer {
    padding: 20px;
  }
}