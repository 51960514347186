.SalesAndImpactContainer {
  padding: 10px 100px 40px 100px;
  gap: 40px;

  /* background: #f9f5ff; */
}

.SalesAndImpactSectionContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 25px 0px;
}

.SalesAndImpactSectionHeader {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  color: #F04438;
}

.SalesAndImpactSectionText {
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  color: #1d2939;
}

.SalesAndImpactImgContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 40px;
}

.SalesAndImpactImgSection {
  width: 324px;
  height: 324px;
  border-radius: 8px;
}

.SalesAndImpactContentSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  width: 876px;
  /* height: 260px; */
}

.SalesAndImpactContentSection ul {
  list-style-type: none;
  margin: 25px 0px;
}

.SalesAndImpactContentSectionPoints {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #475467;
}


@media (max-width:550px) {
  .SalesAndImpactImgContainer {
    flex-direction: column !important;
  }

  .SalesAndImpactContentSection {
    width: unset;
  }

  .SalesAndImpactContainer {
    padding: 20px;
  }
}