.accordion {
  margin-bottom: 10px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e4e7ec;
  border-radius: 4px;
}

.accordionHeader {
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.accordionHeader h3 {
  margin: 0;
}

.accordionTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #1d2939;
}

.accordionHeader.open {
  background-color: #e1e1e1;
}

.arrow {
  font-size: 20px;
}

.accordionContent {
  padding: 10px;
}

@media (max-width: 768px) {
  .accordionHeader h3 {
    font-size: 18px;
  }

  .accordionContent {
    padding: 5px;
  }
}
