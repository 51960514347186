.TermsAndConditions__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 100px;
  gap: 20px;
  background: #ffffff;
}

.TermsAndConditions__heading {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #1d2939;
  margin-bottom: 10px;
}

.TermsAndConditions__heading1 {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #F04438;
  margin-bottom: 10px;
}

ul {
  list-style-type: none;
  padding-left: 0rem;
}

.TermsAndConditions__content {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #475467;
}


@media (max-width:550px) {
  .TermsAndConditions__container {
    padding: 20px;
  }
}