.IndustrySectionBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 24px;
  gap: 20px;
  background: #ffffff;
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  margin: 15px 0px;
}

.IndustrySectionIconSection {
  display: flex;
  align-items: center;
}

.IndustrySectionContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 25px 0px;
}

.IndustrySectionHeader {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  color: #F04438;
}

.IndustrySectionText {
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  color: #1d2939;
}