.HeroSectionContainer {
  /* display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; */
  /* padding: 60px 100px 30px 100px; */
  /* gap: 59px; */
  /* width: 1440px; */
  /* height: 500px; */
  background: #f9f5ff;
  /* flex: none;
  order: 1;
  flex-grow: 0; */
}

.HeroSectionLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;
  width: 509px;
  height: 348px;
  order: 0;
}

.HeroSectionLeftContent {
  /* gap: 16px;
  width: 509px;
  height: 259px;
  flex: none;
  align-self: stretch;
  font-family: "Gilroy";
  font-style: normal; */
}

.HeroSectionLeftHeading {
  font-weight: 700;
  font-size: 48px;
  color: #F04438;

}

.HeroSectionLeftText {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #475467;
}

.HeroSectionleftButtonContainer {
  /* display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: 362px;
  height: 49px; */
}

.HeroSectionleftButton1 {
  padding: 12px 40px;
  gap: 12px;
  width: 167px;
  height: 49px;
  background: #F04438;
  border: 1px solid #F04438;
  border-radius: 4px;
  color: white;
}

.HeroSectionleftButton2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;
  width: 179px;
  height: 49px;
}

.HeroSectionRight,
.HeroSectionRightImg {
  width: 672px;
  height: 539px;
}

@media (max-width:550px) {
  .HeroSectionContainer {
    /* flex-direction: column;
    height: unset;
    padding: 20px; */
  }

  .HeroSectionLeft {
    width: 100%;
  }

  .HeroSectionLeftContent {
    height: unset;
    width: unset;
  }

  .HeroSectionRight,
  .HeroSectionRightImg {
    width: 387px;
    height: 337px;
    margin-top: 5rem !important;
  }
}