.Section2Container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 100px;
  gap: 40px;
  background: #ffffff;
  flex: none;
  order: 2;
  flex-grow: 0;
}

.Section2Content li{
    list-style-type: none;
}
.Section2Row {
  display: flex;
  align-items: center;
}

.Section2Heading {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #1d2939;
}

.Section2Content {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #475467;
  /* margin: 1rem -2rem; */
}


@media (max-width:550px){
  .Section2Container{
    padding: 20px;
  }
  .Section2LeftImg{
    width: 340px;
    height: 400px;
    margin: 3rem 0rem;
  }

  .Section2Content{
    margin: 1rem -1rem 1rem 0rem;
  }
}